/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components), {Type, Params} = _components;
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Overview"), "\n", React.createElement(_components.p, null, "The Layout component arranges slider elements by setting width, height, margin and padding\naccording to the latest options."), "\n", React.createElement(_components.h2, {
    id: "methods",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#methods",
    "aria-label": "methods permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Methods"), "\n", React.createElement(_components.h3, {
    id: "listsize",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#listsize",
    "aria-label": "listsize permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "listSize()")), "\n", React.createElement(Type, {
    code: 'listSize(): number'
  }), "\n", React.createElement(_components.p, null, "Returns the list width for the horizontal slider, or the height for the vertical slider."), "\n", React.createElement(Params, {
    ret: 'The size of the list element in pixel.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "slidesize",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#slidesize",
    "aria-label": "slidesize permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "slideSize()")), "\n", React.createElement(Type, {
    code: 'slideSize( index: number, withoutGap?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "Returns the slide width for the horizontal slider, or the height for the vertical slider."), "\n", React.createElement(Params, {
    params: [['index', 'Optional. A slide index.'], ['withoutGap', 'Optional. Determines whether to exclude the gap amount or not.']],
    ret: 'The size of the slide element in pixel.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "slidersize",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#slidersize",
    "aria-label": "slidersize permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "sliderSize()")), "\n", React.createElement(Type, {
    code: 'sliderSize(): number'
  }), "\n", React.createElement(_components.p, null, "Returns the slider size without clones before the first slide."), "\n", React.createElement(Params, {
    ret: 'The width or height of the slider without clones.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "totalsize",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#totalsize",
    "aria-label": "totalsize permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "totalSize()")), "\n", React.createElement(Type, {
    code: 'totalSize( index?: number, withoutGap?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "Returns the total width or height of slides from the head of the slider to the specified index.\nThis includes sizes of clones before the first slide."), "\n", React.createElement(Params, {
    params: [['index', 'Optional. A slide index. If omitted, uses the last index.'], ['withoutGap', 'Optional. Determines whether to exclude the last gap or not.']],
    ret: 'The total width of slides in the horizontal slider, or the height in the vertical one.'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getpadding",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getpadding",
    "aria-label": "getpadding permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getPadding()")), "\n", React.createElement(Type, {
    code: 'getPadding( right: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "Returns the padding value."), "\n", React.createElement(Params, {
    params: [['right', 'Determines whether to get `paddingRight/Bottom` or `paddingLeft/Top`.']],
    ret: 'The padding value in pixel.'
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
